import React from 'react'
import "./home.scss";
import { Sidebar } from '../../components/sidebar/Sidebar';
import { Navbar } from '../../components/navbar/Navbar';
import { Widget } from '../../components/widget/Widget';
import { Featured } from '../../components/featured/Featured';
import { Chart } from '../../components/chart/Chart';
import { List } from '../../components/table/Table';
import { useAuth } from '../../context/auth/AuthContext';
export const Home = () => {
  const auth = useAuth();
  //console.log("isAuth: ", isAuthenticated())
  return (
    <div className='home'>
        <Sidebar />
        <div className="homeContainer">
          <Navbar />
          <div className="widgets">
            <Widget type="user" />
            <Widget type="inventory" />
            <Widget type="orders" />
            <Widget type="earnings" />
            <Widget type="balance" />
          </div>
          <div className="charts">
            <Featured />
            <Chart aspect={2 / 1} title="Last 6 Months (Revenue)" />
          </div>
          <div className="listContainer">
            <div className="listTitle">Inventory Latest</div>
            <List />
          </div>
        </div>
    </div>
  )
}
