export const postData = async (url = "", data = {}) => {
    // Default options are marked with *
    const response = await fetch(url, {
    method: "POST", 
    headers: {
        "Content-Type": "application/json",
        "API_KEY":"64c15a0c5312a57fce47a3d4"
    },
    body: JSON.stringify(data),
    });
    return response.json();
}
export const getData = async (url = "", data = {}, token = "", prod_url="") => {
    // Default options are marked with *
    const response = await fetch(url, {
    method: "GET", 
    headers: {
        "Content-Type": "application/json",
        'Accept':'application/json;version=2.0',
        "API_KEY":"64c15a0c5312a57fce47a3d4",
        "ACCESS_TOKEN":token,
        "PROD_URL":prod_url
    }
    });
    return response.json();
}
export const putData = async (url = "", data = {}) => {
    // Default options are marked with *
    const response = await fetch(url, {
    method: "PUT", 
    headers: {
        "Content-Type": "application/json",
        "API_KEY":"64c15a0c5312a57fce47a3d4"
    },
    body: JSON.stringify(data),
    });
    return response.json();
}