import React from 'react';
import "./credentials.scss";
import { Sidebar } from '../../components/sidebar/Sidebar';
import { Navbar } from '../../components/navbar/Navbar';
import { styled } from '@mui/material/styles';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { InnovateCredentials } from './credentials/innovateCredentials/innovateCredentials';
const AntTabs = styled(Tabs)({
    borderBottom: '1px solid #e8e8e8',
    '& .MuiTabs-indicator': {
      backgroundColor: '#1890ff',
    },
  });
  const AntTab = styled((props) => <Tab disableRipple {...props} />)(
    ({ theme }) => ({
      textTransform: 'none',
      minWidth: 0,
      [theme.breakpoints.up('sm')]: {
        minWidth: 0,
      },
      fontWeight: theme.typography.fontWeightRegular,
      marginRight: theme.spacing(1),
      color: 'rgba(0, 0, 0, 0.85)',
      fontFamily: [
        '-apple-system',
        'BlinkMacSystemFont',
        '"Segoe UI"',
        'Roboto',
        '"Helvetica Neue"',
        'Arial',
        'sans-serif',
        '"Apple Color Emoji"',
        '"Segoe UI Emoji"',
        '"Segoe UI Symbol"',
      ].join(','),
      '&:hover': {
        color: '#40a9ff',
        opacity: 1,
      },
      '&.Mui-selected': {
        color: '#1890ff',
        fontWeight: theme.typography.fontWeightMedium,
      },
      '&.Mui-focusVisible': {
        backgroundColor: '#d1eaff',
      },
    }),
  );
export const Credentials = () => {
    const [value, setValue] = React.useState(0);
  const renderSwitch = (param) => {
      switch(param) {
          case 0:
            return <InnovateCredentials />;
          default:
            return <></>;
        }
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className='credentials'>
        <Sidebar />
        <div className='newContainer'>
            <Navbar />
            <div className='apitabs'>
                <h1 className='title'>API Credentials</h1>
                <Box sx={{ width: '100%', border:"1px solid #ccc" }}>
                    <Box sx={{ bgcolor: '#fff' }}>
                        <AntTabs value={value} onChange={handleChange} aria-label="ant example">
                        <AntTab label="Innovate API Credentials" />
                        </AntTabs>
                        <Box sx={{ p: 3 }} label="listDatapoints">{renderSwitch(value)}</Box>
                    </Box>
                </Box>
            </div>
        </div>
    </div>
  )
}
