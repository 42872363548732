import React, { useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";

export const Protected = (props) => {
  const {Component} = props;
  const navigate = useNavigate();
  useEffect(() => {
    let token = localStorage.getItem("token");
    if(!token){
      navigate("/login")
    }
  }, [])
  return (
    <div>
      <Component />
    </div>
  )
}
