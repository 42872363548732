import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { API_URL } from '../../../environment';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { getData } from '../../../functions/functions';
export const Targeting = () => {
    let { surveyid } = useParams();
    const [data, setData] = useState([]);
    const [targetdata, setTargetData] = useState([]);
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };
    useEffect(() => {
        fetch(`${API_URL}/invt/surveys/${surveyid}`)
        .then(res => res.json())
        .then(data => {
            if(data.length){
                setData(data)
            }
        })
    }, [])
    const fetchTargeting = (surveyid) => {
      getData(`${API_URL}/invt/surveys/targetinglatest/${surveyid}`)
      .then(result => {
        if(typeof(result) === "object"){
          if(typeof(result.data) === "string"){
            if(JSON.parse(result.data).apiStatus === "Success"){
              setTargetData(JSON.parse(result.data).result)
            }
          } else {}
        }
        // console.log(typeof(result), result, typeof(result.data))
        // if(typeof(result) === "object"){
        //   if(typeof(result.data) === "string"){
        //     if(JSON.parse(result.data).apiStatus === "success"){
        //       console.log(JSON.parse(result.data).result !== undefined, JSON.parse(result.data).result)
        //       if(JSON.parse(result.data).result !== undefined){
        //         setData(JSON.parse(result.data).result)
        //       } else {
        //         alert(JSON.parse(result.data).msg);
        //       }
              
        //     }
        //   }
        // }
        
      })
    }
    const fetchUpdates = (surveyid) => {
      getData(`${API_URL}/invt/surveys/latest/${surveyid}`)
      .then(result => {
        console.log(typeof(result), result, typeof(result.data))
        if(typeof(result) === "object"){
          if(typeof(result.data) === "string"){
            if(JSON.parse(result.data).apiStatus === "success"){
              console.log(JSON.parse(result.data).result !== undefined, JSON.parse(result.data).result)
              if(JSON.parse(result.data).result !== undefined){
                setData(JSON.parse(result.data).result)
              } else {
                alert(JSON.parse(result.data).msg);
              }
              
            }
          }
        }
        
      })
    }
  return (
    <div>
      <button onClick={() => fetchUpdates(surveyid)}>fetch updates</button>
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
        {data.length ? (
        <table style={{textAlign:"left", border:"0.5px solid", margin:20}} border="1">
            <tr><th>Survey ID:</th><td>{data[0].surveyId}</td></tr>
            <tr><th>Survey name:</th><td>{data[0].surveyName}</td></tr>
            <tr><th>N:</th><td>{data[0].N}</td></tr>
            <tr><th>isRevShr:</th><td>{data[0].isRevShr}</td></tr>
            <tr><th>supCmps:</th><td>{data[0].supCmps}</td></tr>
            <tr><th>Remaining N:</th><td>{data[0].remainingN}</td></tr>
            <tr><th>LOI:</th><td>{data[0].LOI}</td></tr>
            <tr><th>IR:</th><td>{data[0].IR}</td></tr>
            <tr><th>Country:</th><td>{data[0].Country}</td></tr>
            <tr><th>Language:</th><td>{data[0].Language}</td></tr>
            <tr><th>Group Type:</th><td>{data[0].groupType}</td></tr>
            <tr><th>Device Type:</th><td>{data[0].deviceType}</td></tr>
            <tr><th>Created Date:</th><td>{data[0].createdDate}</td></tr>
            <tr><th>Modified Date:</th><td>{data[0].modifiedDate}</td></tr>
            <tr><th>Recontacts:</th><td>{data[0].reContact === 0 ? (<>False</>):(<>True</>)}</td></tr>
            <tr><th>Entry Link:</th><td>{data[0].entryLink}</td></tr>
            <tr><th>Test Entry Link:</th><td>{data[0].testEntryLink}</td></tr>
            <tr><th>Targeting Data: </th><td>{data[0].targeting !== null ? 
              typeof(data[0].targeting) === "object" ? (
                <>
                {data[0].targeting.length ? (
                <ol>
                  {data[0].targeting.map(target => {
                    return (
                    <li>
                      <b>Question ID:</b> <i>{target.QuestionId}</i>,  
                      <b>Question Key:</b> <i>{target.QuestionKey}</i>, 
                      <b>Question Text:</b> <i>{target.QuestionText}</i>, 
                      <b>Question Type:</b> <i>{target.QuestionType}</i>, 
                      <b>Question Category:</b> <i>{target.QuestionCategory}</i>
                      <b>Options: </b>
                      <ul>
                        {target.Options.length ? (
                        <>
                        {target.Options.map(option => {
                          return (<>
                            {option.OptionText === undefined ? (
                            <>
                            <li>
                              Option ID: {option.OptionId}, {option.ageStart !== undefined ? (
                              <>
                              Age Start: {option.ageStart}, Age End: {option.ageEnd}
                              </>
                              ):(<></>)}
                            </li>
                            </>
                            ):(
                            <>
                            <li>
                              Option ID: {option.OptionId}, Option Text: {option.OptionText}
                            </li>
                            </>
                            )}
                          </>)
                        })}
                        </>
                        ): (<></>)}
                      </ul>
                    </li>
                    )
                  })}
                </ol>
                ):(<></>)}
                </>
                ):(
                <>
                {data[0].targeting !== undefined ? (
                  JSON.parse(data[0].targeting).length ? (
                    <ol>
                      {JSON.parse(data[0].targeting).map(target => {
                        return (
                        <li>
                          <b>Question ID:</b> <i>{target.QuestionId}</i>,  
                          <b>Question Key:</b> <i>{target.QuestionKey}</i>, 
                          <b>Question Text:</b> <i>{target.QuestionText}</i>, 
                          <b>Question Type:</b> <i>{target.QuestionType}</i>, 
                          <b>Question Category:</b> <i>{target.QuestionCategory}</i>
                          <b>Options: </b>
                          <ul>
                            {target.Options.length ? (
                            <>
                            {target.Options.map(option => {
                              return (<>
                                {option.OptionText === undefined ? (
                                <>
                                <li>
                                  Option ID: {option.OptionId}, {option.ageStart !== undefined ? (
                                  <>
                                  Age Start: {option.ageStart}, Age End: {option.ageEnd}
                                  </>
                                  ):(<></>)}
                                </li>
                                </>
                                ):(
                                <>
                                <li>
                                  Option ID: {option.OptionId}, Option Text: {option.OptionText}
                                </li>
                                </>
                                )}
                              </>)
                            })}
                            </>
                            ): (<></>)}
                          </ul>
                        </li>
                        )
                      })}
                    </ol>
                    ):(<></>)
                ): (<>NULL</>)}
                </>
                )
            : (<>Null</>)}</td></tr>
            <tr><th>IS PIIRequired:</th><td>{data[0].isPIIRequired === 0 ? (<>False</>):(<>True</>)}</td></tr>
            <tr><th>CPI:</th><td>{data[0].CPI}</td></tr>
            <tr><th>Is Quota:</th><td>{data[0].isQuota === 0 ? (<>False</>):(<>True</>)}</td></tr>
            <tr><th>Job Category:</th><td>{data[0].jobCategory}</td></tr>
            <tr><th>Job Id:</th><td>{data[0].JobId}</td></tr>
            <tr><th>Buyer Id:</th><td>{data[0].BuyerId}</td></tr>
            <tr><th>duplicateSurveyIds:</th><td>{data[0].duplicateSurveyIds}</td></tr>
            <tr><th>duplicateCheckLevel:</th><td>{data[0].duplicateCheckLevel}</td></tr>
            <tr><th>statuses:</th><td>{JSON.stringify(data[0].statuses)}</td></tr>
            <tr><th>Expected End Date:</th><td>{data[0].expected_end_date}</td></tr>
            <tr><th>Created Date at Precise:</th><td>{data[0].created_at}</td></tr>
            <tr><th>Updated at Precise:</th><td>{data[0].updated_at}</td></tr>
        </table>
        ):(<></>)}
        <button onClick={() => fetchTargeting(surveyid)} style={{marginBottom:10}}>get Targeting Data</button>
        <div style={{padding:20}}>
          {targetdata.length ? (
          <>
          <ol>
                      {targetdata.map(target => {
                        return (
                        <li>
                          <b>Question ID:</b> <i>{target.QuestionId}</i>,  
                          <b>Question Key:</b> <i>{target.QuestionKey}</i>, 
                          <b>Question Text:</b> <i>{target.QuestionText}</i>, 
                          <b>Question Type:</b> <i>{target.QuestionType}</i>, 
                          <b>Question Category:</b> <i>{target.QuestionCategory}</i>
                          <b>Options: </b>
                          <ul>
                            {target.Options.length ? (
                            <>
                            {target.Options.map(option => {
                              return (<>
                                {option.OptionText === undefined ? (
                                <>
                                <li>
                                  Option ID: {option.OptionId}, {option.ageStart !== undefined ? (
                                  <>
                                  Age Start: {option.ageStart}, Age End: {option.ageEnd}
                                  </>
                                  ):(<></>)}
                                </li>
                                </>
                                ):(
                                <>
                                <li>
                                  Option ID: {option.OptionId}, Option Text: {option.OptionText}
                                </li>
                                </>
                                )}
                              </>)
                            })}
                            </>
                            ): (<></>)}
                          </ul>
                        </li>
                        )
                      })}
                    </ol>
          </>
          ): (<>Null</>)}
        </div>
    </div>
  )
}
