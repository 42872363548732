import React, { useContext } from 'react'
import "./sidebar.scss";
import DashboardIcon from '@mui/icons-material/Dashboard';
import PeopleOutlinedIcon from '@mui/icons-material/PeopleOutlined';
import AccountTreeOutlinedIcon from '@mui/icons-material/AccountTreeOutlined';
import ApiIcon from '@mui/icons-material/Api';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import WysiwygOutlinedIcon from '@mui/icons-material/WysiwygOutlined';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import AccountCircleOutlinedIcon from '@mui/icons-material/AccountCircleOutlined';
import DnsIcon from '@mui/icons-material/Dns';
import WebStoriesOutlinedIcon from '@mui/icons-material/WebStoriesOutlined';
import {Link} from "react-router-dom";
import { DarkModeContext } from '../../context/darkmode/darkModeReducer';
export const Sidebar = () => {
    const { dispatch } = useContext(DarkModeContext)
  return (
    <div className='sidebar'>
        <div className="top">
            <Link to="/" style={{textDecoration:"none"}}><span className="logo">RJ Sampling</span></Link>
            
        </div>
        <hr />
        <div className="center">
            <ul>
                <p className="title">MAIN</p>
                <li>
                    <DashboardIcon />
                    <Link to="/" style={{textDecoration:"none"}}><span>Dashboard</span></Link>
                    
                </li>
                <p className="title">LIST</p>
                <li>
                    <PeopleOutlinedIcon />
                    <Link to="/users" style={{textDecoration:"none"}}>
                    <span>Users</span>
                    </Link>
                    
                </li>
                <li>
                    <PeopleOutlinedIcon />
                    <Link to="/suppliers" style={{textDecoration:"none"}}>
                    <span>Suppliers</span>
                    </Link>
                </li>
                
                <li>
                    <ApiIcon />
                    <Link to="/innovateapi" style={{textDecoration:"none"}}>
                    <span>INNOVATE API</span>
                    </Link>
                    
                </li>
                <li>
                    <AccountTreeOutlinedIcon />
                    <Link to="/credentials" style={{textDecoration:"none"}}>
                    <span>API Credentials</span>
                    </Link>
                    
                </li>
                <li>
                    <AccountTreeOutlinedIcon />
                    <Link to="/redirects" style={{textDecoration:"none"}}>
                    <span>Redirects</span>
                    </Link>
                    
                </li>
                {/* <li>
                    <AccountTreeOutlinedIcon />
                    <Link to="/projects" style={{textDecoration:"none"}}>
                    <span>Projects</span>
                    </Link>
                    
                </li>
                <li>
                    <InventoryOutlinedIcon />
                    <Link to="/inventory" style={{textDecoration:"none"}}>
                    <span>Inventory</span>
                    </Link>
                    
                </li>
                <li>
                    <WysiwygOutlinedIcon />
                    <Link to="/system-health" style={{textDecoration:"none"}}>
                    <span>System health</span>
                    </Link>
                    
                </li>
                <li>
                    <SettingsOutlinedIcon />
                    <Link to="/settings" style={{textDecoration:"none"}}>
                    <span>Settings</span>
                    </Link>
                    
                </li>
                <li>
                    <AccountCircleOutlinedIcon />
                    <Link to="/profile" style={{textDecoration:"none"}}>
                    <span>Profile</span>
                    </Link>
                    
                </li> */}
                <p className="title">USEFULL LINKS</p>
                <li>
                    <WebStoriesOutlinedIcon />
                    <span>Logs</span>
                </li>
                <li>
                    <DnsIcon />
                    <Link to="/performance" style={{textDecoration:"none"}}>
                    <span>Performance Monitor</span>
                    </Link>
                    
                </li>
            </ul>
        </div>
        <div className="bottom">
            <div className="colorOption" onClick={() => dispatch({type:"LIGHT"})}></div>
            <div className="colorOption" onClick={() => dispatch({type:"DARK"})}></div>
        </div>
    </div>
  )
}
