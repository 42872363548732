import React, { useEffect } from 'react'
import { getData } from '../../../functions/functions'
import { API_URL } from '../../../environment'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { Button } from '@mui/material';
export const LiveSurveys = () => {
    const columns = [
        { field: 'surveyId', headerName: 'ID', width: 100 },
        { field: 'surveyName', headerName: 'surveyName', width: 150 },
        { field: 'CPI', headerName: 'CPI', width: 70},
        { field: 'CountryCode', headerName: 'C-Code', width: 70 },
        { field: 'IR', headerName: 'IR', width: 40 },
        { field: 'LOI', headerName: 'LOI', width: 50 },
        { field: 'LanguageCode', headerName: 'L-Code', width: 60 },
        { field: 'N', headerName: 'N', width: 50 },
        { field: 'createdDate', headerName: 'createdDate', width: 150 },
        { field: 'deviceType', headerName: 'devType', width: 70 },
        { field: 'groupType', headerName: 'grpType', width: 70 },
        { field: 'numberOfCompletes', headerName: 'nComp', width: 60 },
        { field: 'numberOfStarts', headerName: 'nStart', width: 60 },
        { field: 'globalBuyerConversion', headerName: 'GBC', width: 60 },
        { field: 'globalMedianLOI', headerName: 'GMLOI', width: 60 },
        { field: 'targeting', headerName: 'targeting', width: 230, renderCell:(params) => {
            return (
                <div className="button"><Link to={"edit/"+params.row.surveyId} target="_blank"><Button variant="contained" style={{width:120, fontSize:12}}>Targeting</Button></Link></div>
            )
        } }
        
    ];
    const getSurveyData = async () => {
        await getData(`${API_URL}/lucid/selectedsurveys`)
        .then(result => console.log(result))
    }
    useEffect(() => {
        getSurveyData()
    }, [])
  return (
    <div className='livesurveys'>
        <div className='inputbutton'>
            {/* <button onClick={getSurveyData()}>Get Surveys</button> */}
        </div>
    </div>
  )
}
