import React, { useEffect, useState } from 'react'
import "./list.scss";
import { Sidebar } from '../../../components/sidebar/Sidebar';
import { Navbar } from '../../../components/navbar/Navbar';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Link } from 'react-router-dom';
import { getData } from '../../../functions/functions';
import { API_URL } from '../../../environment';
export const SupplierList = () => {
    const [data, setData] = useState([]);
    const handleDelete = (id) => {
        setData(data.filter((item) => item.id !== id))
    }
    useEffect(() => {
        getData(`${API_URL}/suppliers/list`)
        .then(data => {
            if(data.success){
                setData(JSON.parse(data.result))
            }
        })
    }, [])
    const userColumns = [
        { field: 'id', headerName: 'ID', width: 50 },
        { field: 'supplier_name', headerName: 'Supplier name', width: 120 },
        { field: 'supplier_code', headerName: 'Supplier Code', width: 350 },
        { field: 'supplier_api_key', headerName: 'API Key', width: 250 },
        { field: 'supplier_dev_url', headerName: 'DEV URL', width: 250 },
        { field: 'supplier_prod_url', headerName: 'PRD URL', width: 200 },
        {
            field: 'key_usage',
            headerName: 'Key Status',
            width: 100,
            renderCell:(params) => {
                return (
                    <div className={`cellWithStatus ${params.row.key_usage}`}>
                        {params.row.key_usage === "1" ? (<>TRUE</>):(<>FALSE</>)}
                    </div>
    
                )
            }
          },
    ]
    const actionColumn = [
        {
            field:"action", 
            headerName:"Action", 
            width:200, 
            renderCell:(params) => {
                return (
                    <div className="cellAction">
                        <div className="viewButton"><Link to="/supplier/1" style={{textDecoration:"none"}}>View</Link></div>
                        <div className="deleteButton" onClick={() => handleDelete(params.row.id)}>Delete</div>
                    </div>
                )
            }
        }
    ];
  return (
    <div className='suppliers'>
        <Sidebar />
        <div className="SupplierContainer">
            <Navbar />
            <div className='supplierData'>
                <div className="datatableTitle">
                    Add New Supplier
                <Link to="new" className="link">Add New</Link>
                </div>
                <DataGrid
                    className="datagrid"
                    rows={data}
                    columns={userColumns.concat(actionColumn)}
                    initialState={{
                    pagination: {
                        paginationModel: { page: 0, pageSize: 10 },
                    },
                    }}
                    pageSizeOptions={[10, 25, 50, 100]}
                    checkboxSelection
                />
            </div>
        </div>
    </div>
  )
}
