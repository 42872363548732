import React from 'react'
import "./widget.scss";
import KeyboardArrowUpOutlinedIcon from '@mui/icons-material/KeyboardArrowUpOutlined';
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import InventoryOutlinedIcon from '@mui/icons-material/InventoryOutlined';
import BorderStyleOutlinedIcon from '@mui/icons-material/BorderStyleOutlined';
import LightbulbOutlinedIcon from '@mui/icons-material/LightbulbOutlined';
import AccountBalanceWalletOutlinedIcon from '@mui/icons-material/AccountBalanceWalletOutlined';
export const Widget = ({ type }) => {
  let data;
  const amount = 100;
  const diff = 20
  switch(type) {
    case "user":
      data = {
        title: "USERS",
        isMoney: false,
        link: "See all users",
        icon: <PersonOutlineOutlinedIcon className='icon' style={{color:"crimson", backgroundColor:"rgba(255, 0, 0, 0.2)"}} />
      };
      break;
      case "inventory":
      data = {
        title: "INVENTORY",
        isMoney: false,
        link: "See all Inventory",
        icon: <InventoryOutlinedIcon className='icon' style={{color:"goldenrod", backgroundColor:"rgba(255, 0, 0, 0.2)"}} />
      };
      break;
      case "orders":
      data = {
        title: "SURVEYS",
        isMoney: false,
        link: "See all Surveys",
        icon: <BorderStyleOutlinedIcon className='icon' style={{color:"green", backgroundColor:"rgba(255, 0, 0, 0.2)"}} />
      };
      break;
      case "earnings":
      data = {
        title: "EARNINGS",
        isMoney: false,
        link: "See all Earning",
        icon: <LightbulbOutlinedIcon className='icon' style={{color:"purple", backgroundColor:"rgba(255, 0, 0, 0.2)"}} />
      };
      break;
      case "balance":
      data = {
        title: "BALANCE",
        isMoney: true,
        link: "See all Balance",
        icon: <AccountBalanceWalletOutlinedIcon className='icon' style={{color:"blue", backgroundColor:"rgba(255, 0, 0, 0.2)"}} />
      };
      break;
      default:
        break;
  }
  
  return (
    <div className='widget'>
        <div className="left">
            <span className="title">{data.title}</span>
            <span className="counter">
              {data.isMoney && "$"} {amount}
            </span>
            <span className="link">{data.link}</span>
        </div>
        <div className="right">
            <div className="percentage positive">
                <KeyboardArrowUpOutlinedIcon />
            {diff}%
            </div>
            {data.icon}
        </div>
    </div>
  )
}
