import React, { useEffect, useState } from 'react';
import { useForm } from "react-hook-form";
import "./innovatecredentials.scss";
import { getData, postData } from '../../../../functions/functions';
import { API_URL } from '../../../../environment';

export const InnovateCredentials = () => {
    const { register, handleSubmit } = useForm();
    const [mydata, setMydata] = useState({api_name: "", api_code: "", access_token:"", production_url:"", dev_url:""})
    useEffect(() => {
        getData(`${API_URL}/cred/verify`)
        .then(result => {
            console.log(result)
            if(result.success){
                setMydata({api_name: result.result.api_name, api_code: result.result.api_code, access_token:result.result.access_token, production_url:result.result.production_url, dev_url:result.result.dev_url})
            }
        })
    }, [])
    const onSubmit = (data) => {
        console.log(data);
        if(data.accessToken !== "" && data.devUrl !== "", data.proUrl !== ""){
            postData(`${API_URL}/cred/innovate`, data)
            .then(result => {
                if(result.success){
                    alert("API Stored successfully.")
                }
            })

        } else {
            console.log('Fail');
        }
    }
    console.log("---", mydata)
  return (
    <div className='innovateCredentails'>
        <div className='form'>
        <div className="bottom">
            <div className="right">
              <form onSubmit={handleSubmit(onSubmit)}>
                <div className="formInput">
                  <label htmlFor="username">Access Token</label>
                  {mydata.api_name !== "" ? (<input type="text" placeholder="Enter Access Token" {...register("accessToken")} value={mydata.access_token} />):(<input type="text" placeholder="Enter Access Token" {...register("accessToken")} />)}
                  
                </div>
                <div className="formInput">
                  <label htmlFor="name">Production URL</label>
                  {mydata.api_name !== "" ? (<input type="text" placeholder="Enter Production URL" {...register("proUrl")} value={mydata.production_url} />):(<input type="text" placeholder="Enter Production URL" {...register("proUrl")} />)}
                  
                </div>
                <div className="formInput">
                  <label htmlFor="email">Development URL</label>
                  {mydata.api_name !== "" ? (<input type="text" placeholder="Enter Development URL" {...register("devUrl")} value={mydata.dev_url} />):(<input type="text" placeholder="Enter Development URL" {...register("devUrl")} />)}
                  
                </div>
                <div className="formInput">
                <input type="hidden" placeholder="Enter API Code" {...register("apiCode")} value={mydata.api_code} />
                </div>
                <div className="formInput">
                </div>
                {mydata.api_name !== "" ? (<></>):(<button>Save</button>)}
              </form>
            </div>
          </div>
        </div>
    </div>
  )
}
