import "./table.scss";
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
const rows = [
    {
        id:"RFG11235101-001",
        title:"Please share your opinion on this product",
        category: "B2B",
        estimated_ir:"100",
        estimated_loi:"5",
        collectsPll:"",
        cpi:"$.47",
        status:"approved"
    },
    {
        id:"RFG11235102-001",
        title:"Please share your opinion on this product",
        category: "B2B",
        estimated_ir:"100",
        estimated_loi:"5",
        collectsPll:"",
        cpi:"$.47",
        status:"approved"
    },
    {
        id:"RFG11235103-001",
        title:"Please share your opinion on this product",
        category: "B2B",
        estimated_ir:"100",
        estimated_loi:"5",
        collectsPll:"",
        cpi:"$.47",
        status:"pending"
    },
    {
        id:"RFG11235104-001",
        title:"Please share your opinion on this product",
        category: "B2B",
        estimated_ir:"100",
        estimated_loi:"5",
        collectsPll:"",
        cpi:"$.47",
        status:"pending"
    },
    {
        id:"RFG11235105-001",
        title:"Please share your opinion on this product",
        category: "B2B",
        estimated_ir:"100",
        estimated_loi:"5",
        collectsPll:"",
        cpi:"$.47",
        status:"approved"
    },
    {
        id:"RFG11235106-001",
        title:"Please share your opinion on this product",
        category: "B2B",
        estimated_ir:"100",
        estimated_loi:"5",
        collectsPll:"",
        cpi:"$.47",
        status:"pending"
    }
];
export const List = () => {
  return (
        <TableContainer component={Paper} className="table">
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell className="tableCell">Id</TableCell>
            <TableCell className="tableCell">Title</TableCell>
            <TableCell className="tableCell">Category</TableCell>
            <TableCell className="tableCell">Estimated IR</TableCell>
            <TableCell className="tableCell">Estimated LOI</TableCell>
            <TableCell className="tableCell">CollectsPll</TableCell>
            <TableCell className="tableCell">CPI</TableCell>
            <TableCell className="tableCell">Status</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <TableRow key={row.id}>
              <TableCell className="tableCell">
                {row.id}
              </TableCell>
              <TableCell className="tableCell">
                <div className="cellWrapper">
                {row.title}
                </div>
              </TableCell>
              <TableCell className="tableCell">{row.category}</TableCell>
              <TableCell className="tableCell">{row.estimated_ir}</TableCell>
              <TableCell className="tableCell">{row.estimated_loi}</TableCell>
              <TableCell className="tableCell">{row.collectsPll}</TableCell>
              <TableCell className="tableCell">{row.cpi}</TableCell>
              <TableCell className="tableCell">
                <span className={`status ${row.status}`}>
                {row.status}
                </span>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  )
}
