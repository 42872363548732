import "./datatable.scss";
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { userColumns, userRows } from "../../datatablesource";
import { Link } from "react-router-dom";
import { useState } from "react";
export const Datatable = () => {
    const [data, setData] = useState(userRows);
    const handleDelete = (id) => {
        setData(data.filter((item) => item.id !== id))
    }
    const actionColumn = [
        {
            field:"action", 
            headerName:"Action", 
            width:200, 
            renderCell:(params) => {
                return (
                    <div className="cellAction">
                        <div className="viewButton"><Link to="/users/1" style={{textDecoration:"none"}}>View</Link></div>
                        <div className="deleteButton" onClick={() => handleDelete(params.row.id)}>Delete</div>
                    </div>
                )
            }
        }
    ];
  return (
    <div className='datatable'>
        <div className="datatableTitle">
            Add New User
        <Link to="/users/new" className="link">Add New</Link>
        </div>
         <DataGrid
            className="datagrid"
            rows={data}
            columns={userColumns.concat(actionColumn)}
            initialState={{
            pagination: {
                paginationModel: { page: 0, pageSize: 10 },
            },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            checkboxSelection
        />
    </div>
  )
}
