import React, { useState } from 'react';
import "./new.scss"
import { Sidebar } from '../../../components/sidebar/Sidebar';
import { Navbar } from '../../../components/navbar/Navbar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { API_URL } from '../../../environment';
import { postData } from '../../../functions/functions';
import {useNavigate} from "react-router-dom";
export const NewSupplier = ({ title }) => {
  const navigate = useNavigate();
    const [supplierName, setSupplierName] = useState("");
    const [supplierCode, setSupplierCode] = useState("");
    const [prodUrl, setProUrl] = useState("");
    const [devUrl, setDevUrl] = useState("");
    const [keySelection, setKeySelection] = useState("");
    const [open, setOpen] = useState(false);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };
    const ButtonSubmit = () => {
        handleOpen();
        postData(`${API_URL}/suppliers/add`, {supplierName, supplierCode, prodUrl, devUrl, keySelection})
        .then(data => {
          if(data.success){
            navigate('/suppliers')
          } else {
            alert("You have bug.")
          }
        })
      }
    const onchangeSupplierName = (event) => {
        setSupplierName(event.target.value)
    }
    const onchangeSupplierCode = (event) => {
        setSupplierCode(event.target.value)
    }
    const onchangeProdUrl = (event) => {
      setProUrl(event.target.value)
    }
    const onchangeDevUrl = (event) => {
      setDevUrl(event.target.value)
    }
    const onchangeKeySelection = (event) => {
        setKeySelection(event.target.value)
    }
  return (
    <div className='newsupplier'>
        <Backdrop
            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
            open={open}
            onClick={handleClose}
        >
            <CircularProgress color="inherit" />
        </Backdrop>
        <Sidebar />
        <div className="newContainer">
        <Navbar />
          <div className="top">
            <h1>{title}</h1>
          </div>
          <div className="bottom">
            <div className="right">
              <div className='form'>
                
                <div className="formInput">
                  <label htmlFor="suppliername">Supplier Name</label>
                  <input type="text" placeholder="Enter Supplier Name" onChange={onchangeSupplierName} />
                </div>
                <div className="formInput">
                  <label htmlFor="name">Add Supplier API Code (If They Provide)</label>
                  <input type="text" placeholder="Enter Supplier API Code" onChange={onchangeSupplierCode} />
                </div>
                <div className="formInput">
                  <label htmlFor="name">Add Supplier Production URL (If They Provide)</label>
                  <input type="text" placeholder="Enter Supplier Production URL" onChange={onchangeProdUrl} />
                </div>
                <div className="formInput">
                  <label htmlFor="name">Add Supplier Development / Sandbox URL (If They Provide)</label>
                  <input type="text" placeholder="Enter Supplier Development / Sandbox URL" onChange={onchangeDevUrl} />
                </div>
                <div className="formInput">
                  <label htmlFor="key">Provide Our Key to Supplier</label>
                  <select onClick={onchangeKeySelection}>
                    <option value="">Select Option</option>
                    <option value="1">Yes</option>
                    <option value="0">No</option>
                  </select>
                </div>
                
                <button onClick={() => ButtonSubmit()}>Save</button>
              </div>
            </div>
          </div>
        </div>
    </div>
  )
}
