export const userColumns = [
    { field: 'id', headerName: 'ID', width: 70 },
    { field: 'firstName', headerName: 'First name', width: 130 },
    { field: 'lastName', headerName: 'Last name', width: 130 },
    {
      field: 'age',
      headerName: 'Age',
      type: 'number',
      width: 90,
    },
    {
      field: 'fullName',
      headerName: 'Full name',
      description: 'This column has a value getter and is not sortable.',
      sortable: false,
      width: 160,
      renderCell: (params) => {
        return (
            <div className="cellWithImg">
                {/* <img className="cellImg" src={params.row.img} alt="avatar" /> */}
            <span>{params.row.lastName}</span> 
            <p>{params.row.age}</p>
            </div>
        )
      }
    },
    {
        field: 'status',
        headerName: 'Status',
        width: 150,
        renderCell:(params) => {
            return (
                <div className={`cellWithStatus ${params.row.status}`}>
                    {params.row.status}
                </div>

            )
        }
      },
]
export const userRows = [
    { id: 1, lastName: 'Singh', firstName: 'Ram', age: 35, status:"active" },
    { id: 2, lastName: 'Sharma', firstName: 'Raju', age: 42, status:"passive" },
    { id: 3, lastName: 'Singh', firstName: 'Raj', age: 45, status:"pending" },
    { id: 4, lastName: 'Jain', firstName: 'Arya', age: 16, status:"active" },
    { id: 5, lastName: 'Singh', firstName: 'Ranveer', age: null, status:"passive" },
    { id: 6, lastName: 'Singh', firstName: "Bhola", age: 150, status:"active" },
    { id: 7, lastName: 'Singh', firstName: 'Deepak', age: 44, status:"pending" },
    { id: 8, lastName: 'Singh', firstName: 'Rossini', age: 36, status:"pending" },
    { id: 9, lastName: 'Singh', firstName: 'Tarun', age: 65, status:"active" },
]