import React from 'react'
import "./single.scss";
import { Sidebar } from "../../components/sidebar/Sidebar";
import { Navbar } from "../../components/navbar/Navbar";
import { List } from '../../components/table/Table';
import {Chart} from "../../components/chart/Chart"
export const Single = () => {
  return (
    <div className='single'>
        <Sidebar />
        <div className="singleContainer">
          <Navbar />
          <div className="top">
            <div className="left">
              <div className="editButton">Edit</div>
              <h1 className="title">Information</h1>
              <div className="item">
                <img src="https://www.hiddentriforce.com/wp-content/gallery/pixel-collie-crafts-custom-breath-of-the-wild-link-amiibo/il_570xN.1185138054_fmr0.jpg" alt="" className='itemImg' />
                <div className="details">
                <h1 className="itemTitle">Ram Singh</h1>
                <div className="detailItem">
                  <span className="itemKey">Email: </span>
                  <span className="itemValue">ramsingh@whanux.com </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Phone: </span>
                  <span className="itemValue">897-908-908-67 </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Address: </span>
                  <span className="itemValue">Elton St. 234 Garden Yd. New York </span>
                </div>
                <div className="detailItem">
                  <span className="itemKey">Country: </span>
                  <span className="itemValue">USA </span>
                </div>
              </div>
              </div>
              
            </div>
            <div className="right">
              <Chart aspect={3 / 1} title="User Visiting (Last 6 Months)" />
            </div>
          </div>
          <div className="bottom">
            <h1 className="title">Last Visiting</h1>
            <List />
          </div>
        </div>
    </div>
  )
}
