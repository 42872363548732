import "./new.scss";
import { Sidebar } from "../../components/sidebar/Sidebar";
import { Navbar } from "../../components/navbar/Navbar";
import DriveFolderUploadOutlinedIcon from '@mui/icons-material/DriveFolderUploadOutlined';
import { useState } from "react";
export const New = ({title}) => {
  const [file, setFile] = useState("")
  return (
    <div className='new'>
        <Sidebar />
        <div className="newContainer">
          <Navbar />
          <div className="top">
            <h1>{title}</h1>
          </div>
          <div className="bottom">
            <div className="left">
              <img src={file ? URL.createObjectURL(file) : "https://static.vecteezy.com/system/resources/previews/008/018/058/original/icon-add-image-upload-image-file-photo-outline-design-free-vector.jpg"} alt="" />
            </div>
            <div className="right">
              <form>
                <div className="formInput">
                  <label htmlFor="file">
                    Image: <DriveFolderUploadOutlinedIcon className="icon" />
                  </label>
                  <input type="file" id="file" onChange={e => setFile(e.target.files[0])} style={{display:"none"}} />
                </div>
                <div className="formInput">
                  <label htmlFor="username">Username</label>
                  <input type="text" placeholder="Enter username" />
                </div>
                <div className="formInput">
                  <label htmlFor="name">Name and Surname</label>
                  <input type="text" placeholder="Enter name and surname" />
                </div>
                <div className="formInput">
                  <label htmlFor="email">Email</label>
                  <input type="email" placeholder="Enter email" />
                </div>
                <div className="formInput">
                  <label htmlFor="phone">Phone</label>
                  <input type="text" placeholder="Enter phone" />
                </div>
                <div className="formInput">
                  <label htmlFor="phone">Password</label>
                  <input type="password" placeholder="Enter password" />
                </div>
                <div className="formInput">
                  <label htmlFor="address">Address</label>
                  <input type="text" placeholder="Enter Address" />
                </div>
                <div className="formInput">
                  <label htmlFor="country">Country</label>
                  <input type="text" placeholder="USA" />
                </div>
                <button>Save</button>
              </form>
            </div>
          </div>
        </div>
    </div>
  )
}
