import React from 'react'
import "./lucidproject.scss";
import { styled } from '@mui/material/styles';
import { Sidebar } from '../../components/sidebar/Sidebar';
import { Navbar } from '../../components/navbar/Navbar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import { LiveSurveys } from './liveServeys/LiveSurveys';
const AntTabs = styled(Tabs)({
  borderBottom: '1px solid #e8e8e8',
  '& .MuiTabs-indicator': {
    backgroundColor: '#1890ff',
  },
});
const AntTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: 'none',
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    fontWeight: theme.typography.fontWeightRegular,
    marginRight: theme.spacing(1),
    color: 'rgba(0, 0, 0, 0.85)',
    fontFamily: [
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    '&:hover': {
      color: '#40a9ff',
      opacity: 1,
    },
    '&.Mui-selected': {
      color: '#1890ff',
      fontWeight: theme.typography.fontWeightMedium,
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#d1eaff',
    },
  }),
);
export const LucidProject = () => {
  const [value, setValue] = React.useState(0);
  const renderSwitch = (param) => {
    switch(param) {
        case 0:
          return <LiveSurveys />;
        default:
          return <></>;
      }
  }
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  return (
    <div className='lucid'>
        <Sidebar />
        <div className='newContainer'>
        <Navbar />
        <div className='apitabs'>
            <h1 className='title'>LUCID API Test Results</h1>
            <Box sx={{ width: '100%', border:"1px solid #ccc" }}>
                <Box sx={{ bgcolor: '#fff' }}>
                    <AntTabs value={value} onChange={handleChange} aria-label="ant example">
                    <AntTab label="Live Surveys (CPI > 2)" />
                    </AntTabs>
                    <Box sx={{ p: 3 }} label="listDatapoints">{renderSwitch(value)}</Box>
                </Box>
                {/* <Box sx={{ bgcolor: '#2e1534' }}>
                    <StyledTabs
                    value={value}
                    onChange={handleChange}
                    aria-label="styled tabs example"
                    >
                    <StyledTab label="Workflows" />
                    <StyledTab label="Datasets" />
                    <StyledTab label="Connections" />
                    </StyledTabs>
                    <Box sx={{ p: 3 }} />
                </Box> */}
            </Box>
        </div>
        </div>
    </div>
  )
}
