import React, { createContext, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
const AuthContext = createContext();
export const useAuth = () => {
    return useContext(AuthContext);
}
export const AuthContextProvider = ({ children }) => {
    const navigate = useNavigate();
    const [user, setUser] = useState(null); // it should contain user data
    const [token, setToken] = useState(null); // it has authentication token
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    const login = (userData, authToken) => {
        setUser(userData);
        localStorage.setItem("user", userData)
        setToken(authToken);
        localStorage.setItem("token", authToken)
        navigate("/");
    }
    const logout = () => {
        console.log("logout")
        setUser(null);
        localStorage.removeItem("user");
        setToken(null);
        localStorage.removeItem("token");
        navigate("/login");
    }
  return (
    <AuthContext.Provider value={{
        user,
        token,
        login,
        logout,
        isAuthenticated
    }}>
        {children}
    </AuthContext.Provider>
  )
}
