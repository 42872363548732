import React, {useMemo, useState} from 'react'
import "./getallocatedsurveys.scss";
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { getData } from '../../../functions/functions';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Targeting } from './targeting';

import { Link, useNavigate } from 'react-router-dom';
import { Button } from '@mui/material';
import { API_URL } from '../../../environment';
export const GetAllocatedSurveys = () => {
    const navigate = useNavigate();
    const columns = [
        { field: 'surveyId', headerName: 'ID', width: 100 },
        { field: 'surveyName', headerName: 'surveyName', width: 150 },
        { field: 'BuyerId', headerName: 'BID', width: 60},
        { field: 'CPI', headerName: 'CPI', width: 70},
        { field: 'CountryCode', headerName: 'C-Code', width: 70 },
        { field: 'IR', headerName: 'IR', width: 40 },
        { field: 'LOI', headerName: 'LOI', width: 50 },
        { field: 'LanguageCode', headerName: 'L-Code', width: 60 },
        { field: 'N', headerName: 'N', width: 50 },
        { field: 'createdDate', headerName: 'createdDate', width: 150 },
        { field: 'deviceType', headerName: 'devType', width: 70 },
        { field: 'groupType', headerName: 'grpType', width: 70 },
        { field: 'numberOfCompletes', headerName: 'nComp', width: 60 },
        { field: 'numberOfStarts', headerName: 'nStart', width: 60 },
        { field: 'globalBuyerConversion', headerName: 'GBC', width: 60 },
        { field: 'globalMedianLOI', headerName: 'GMLOI', width: 60 },
        { field: 'targeting', headerName: 'targeting', width: 230, renderCell:(params) => {
            return (
                <div className="button"><Link to={"targeting/"+params.row.surveyId} target="_blank"><Button variant="contained" style={{width:120, fontSize:12}}>Targeting</Button></Link></div>
            )
        } }
        
    ];
    const [open, setOpen] = useState(false);
    const [mydata, setMydata] = useState({api_name: "", api_code: "", access_token:"", production_url:"", dev_url:""})
    const [getdetails, setGetdetails] = useState([]);
    const handleClose = () => {
        setOpen(false);
    };
    const handleOpen = () => {
        setOpen(true);
    };
    useMemo(() => {
        handleOpen();
        var data = {}
        getData(`${API_URL}/cred/verify`)
        .then(result => {
            console.log("CRED", result)
            if(result.success){
                getData(`${API_URL}/invt/surveys`, data, result.result.access_token, result.result.production_url)
                .then(rdata => {
                    console.log(rdata)
                    if(rdata.success){
                        setGetdetails(JSON.parse(rdata.data));
                    } else {
                        setGetdetails([])
                    }
                    //setGetdetails((data).result);
                    handleClose()
                })
                setMydata({api_name: result.result.api_name, api_code: result.result.api_code, access_token:result.result.access_token, production_url:result.result.production_url, dev_url:result.result.dev_url})
            } else {
                setGetdetails([]);
                handleClose();
                navigate("/credentials")
            }
        })
    }, [])
    const handleButtonClick = () => {
        handleOpen()
        getData(`${API_URL}/invt/supply/getallocatesurvey`)
        .then(result => {
           if(result.success){
            setGetdetails([]);
            alert("Data stored successfully.");
           }
            handleClose()
        })
        .catch(err => console.log(err))
    };
  return (
    <div className='getalloc'>
        <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
        onClick={handleClose}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
        <div className='button'>
            <button className='getnow' onClick={() => {handleButtonClick()}}>Get Now</button>
        </div>
        <div className='viewdata'>
        <DataGrid
            rows={getdetails}
            columns={columns}
            initialState={{
            pagination: {
                paginationModel: { page: 0, pageSize: 10 },
            },
            }}
            pageSizeOptions={[10, 25, 50, 100]}
            getRowId={(row) => row.surveyId}
            getRowHeight={() => 'auto'}
            checkboxSelection
            style={{fontSize:15, width:"100%"}}
        />
        </div>
    </div>
  )
}
