import {
  Routes,
  Route,
  BrowserRouter
} from "react-router-dom";
import { Home } from "./pages/home/Home";
import { Login } from "./pages/login/Login";
import { List } from "./pages/list/List";
import { Single } from "./pages/single/Single";
import { New } from "./pages/new/New";
import "./style/dark.scss";
import { useContext } from "react";
import { DarkModeContext } from "./context/darkmode/darkModeReducer";
import { AuthContextProvider } from "./context/auth/AuthContext";
import { Protected } from "./Protected";
import { InnovateProject } from "./vendorProjects/innovateproject/InnovateProject";
import { LucidProject } from "./vendorProjects/lucidproject/LucidProject";
import { Targeting } from "./vendorProjects/innovateproject/getallocSurvey/targeting";
import { SupplierList } from "./pages/suppliers/list/Supplier.List";
import { NewSupplier } from "./pages/suppliers/new/NewSupplier";
import { Credentials } from "./vendorProjects/innovateproject/credentials";
function App() {
  const {darkMode} = useContext(DarkModeContext);
  return (
    <div className={darkMode ? "app dark" : "app"}>
      <BrowserRouter>
      <AuthContextProvider>
        <Routes>
          <Route path="/">

            <Route excact path='/' element={<Protected Component={Home} />} />
            <Route path="login" element={<Login />} />
            <Route path="users" element={<Protected Component={List} />}>
              <Route index element={<List />} />
              <Route path=":userId" element={<Protected Component={Single} />} />
              <Route path="new" element={<Protected Component={New} title="Add New User" />} />
            </Route>
            <Route path="suppliers">
              <Route index element={<SupplierList add="Add New User" />} />
              <Route path="new" element={<Protected Component={NewSupplier} title="Add New Supplier" add="Add New Supplier" />} />
            </Route>
            <Route path="suppliers" element={<Protected Component={SupplierList} />}>
              {/* <Route index element={<SupplierList />} /> */}
              <Route path="new" element={<Protected Component={New} title="Add New User" />} />
              {/* <Route path=":supId" element={<Protected Component={Single} />} /> */}
              {/* <Route path="new" element={<NewSupplier />} /> */}
            </Route>
            <Route path="products">
              <Route index element={<List />} />
              <Route path=":productId" element={<Single />} />
              <Route path="new" element={<New title="Add new Product" />} />
            </Route>
            <Route path="innovateapi" element={<Protected Component={InnovateProject} />}></Route>
            <Route path="credentials" element={<Protected Component={Credentials} />}></Route>
            <Route path="innovateapi/targeting/:surveyid" render={(props) => <Targeting {...props} />} element={<Targeting />} />
            <Route path="lucidapi" element={<Protected Component={LucidProject} />}></Route>
          </Route>
        </Routes>
        </AuthContextProvider>
        </BrowserRouter>
    </div>
  );
}

export default App;
