import React, { useEffect } from 'react'
import "./login.scss";
import {Button, CssBaseline, TextField, FormControlLabel, Checkbox, Link, Grid, Box, Typography, Container} from '@mui/material';
import { postData } from '../../modules/apirequest';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { useAuth } from '../../context/auth/AuthContext';
import { useNavigate } from 'react-router-dom';
import Logo from "../../assets/precise-logo.jpeg"

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      {'Copyright © '}
      <Link color="inherit" href="https://precise.rjsampling.com/">
        Precise
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

// TODO remove, this demo shouldn't need to reset the theme.

const defaultTheme = createTheme();
export const Login = () => {
  const auth = useAuth();
  const navigate = useNavigate();
  
  const handleSubmit = (event) => {
    event.preventDefault();
    const vdata = new FormData(event.currentTarget);
    const formdata = {username: vdata.get('username'), password: vdata.get('password')}
    postData("https://precise.rjsampling.com/auth/login", formdata).then((data) => {
      var user = data.user;
      var token = data.token;
      auth.login(user, token)
    });
  };
  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component="main" maxWidth="xs" className='container'>
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
            {/* <LockOutlinedIcon /> */}
            {/* <img  className="logo" src={Logo} alt='' /> */}
            <Typography component="h1" variant="h5">
            ICONIC
          </Typography>
          <Typography component="h1" variant="h5">
            Admin panel
          </Typography>
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="username"
              label="Username"
              name="username"
              autoComplete="username"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <FormControlLabel
              control={<Checkbox value="remember" color="primary" />}
              label="Remember me"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Login
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="#" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              
            </Grid>
          </Box>
        </Box>
        <Copyright sx={{ mt: 8, mb: 4 }} />
      </Container>
    </ThemeProvider>
  )
}
